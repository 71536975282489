import React, { useEffect, useState } from "react";
import { dynamicRequest, useDynamicSelector } from "../../../services/redux";
import { get_cash_gl_accounts_query } from "../../../services/redux/slices/gl_accounts/graphql";
import { create_dividend_payout_mutation } from "../../../services/redux/slices/dividend_setting/dividend_setting";
import { Box, HStack } from "native-base";
import { Button, Col, Row, Form as AntdNativeForm } from "antd";
import Form from "@views/components/ui/antd_form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CommonTransactionMode from "@common/common_transaction_mode";

const DividendPayoutDetails = (props) => {
  const { items: cash_accounts } = useDynamicSelector("getGlCashAccounts");
  const {loading} = useDynamicSelector("dividend_payout");
  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = AntdNativeForm.useForm();

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const payout = (values)=>{
    const { payment_gl_id, to_gl_account_id, ...additional_data } = values;
    let key = [{ key: "dividend_payout", loading: true }];
    let query = create_dividend_payout_mutation;
    const member_ids = props.member_id_list.map((member) => member.member_id);
    let variables = {
      data:{
        financial_year: props?.financialYear,
        payment_gl_id: values.to_gl_account_id,
        members: member_ids,
      },
      additional_data: {
        ...additional_data,
        date: additional_data?.date ?? accountingDate,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  }

  const handleSubmit = (values) => {
    payout(values)
  };

  useEffect(() => {
    getGlCashAccounts();
  }, []);

  const getGlCashAccounts = () => {
    let key = [{ key: "getGlCashAccounts", loading: true }];
    let query = get_cash_gl_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
      <Box >
        <AntdNativeForm
          onFinish={handleSubmit}
          id={"for"}
          form={form}
          layout="vertical"
        >
          <Row gutter={16}>
            {/* <Col span={12}>
              <Form.Select
                label={t("form:payment_account")}
                field={`payment_gl_id`}
                options={cash_accounts}
                labelField={"name"}
                valueField={"id"}
                rules={[
                  {
                    required: true,
                    message: t("error:payment_account_is_required"),
                  },
                ]}
              />
            </Col> */}
            <CommonTransactionMode transaction_type={"payment"} form={form} />
          </Row>
          <HStack space={"3"} justifyContent={"flex-end"} >
            <Button
              width="100px"
              danger
              variant="outline"
              onClick={props.close}
            >
              {t("Cancel")}
            </Button>
            <Button
              loading={loading}
              width="100px"
              colorScheme="primary"
              type="primary"
              onClick={() => form.submit()} 
            >
              {t("submit")}
            </Button>
          </HStack>
        </AntdNativeForm>
      </Box>
  );
};

export default DividendPayoutDetails;
