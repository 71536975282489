import React, { useEffect, useRef, useState } from "react";
import AntdDynamicForm from "../../components/ui/antd_form/antd_form";
import { Button, Col, DatePicker, Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import { HStack, Text } from "native-base";
import {
  dynamicRequest,
  query_accounting_date,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import { amountFormat } from "../../../helpers/utils";
import { useReactToPrint } from "react-to-print";
import { Margin, usePDF } from "react-to-pdf";
import ReportHeader from "../../components/ui/report_header/report_header";
import { query_get_gl_accounts_summary } from "../../../services/redux/slices/dynamic_entity/graphql/graphql_gl_accounts";
import { showToast } from "@helpers/toast";
import GlStatementPDF from "./gl_statement_pdf";
import {
  change_month_wise,
  get_last_day,
  re_change,
} from "../../../helpers/constants";
import { disabled_range, to_disabled_range } from "../../../helpers/functions";

const GlStatementModal = (props) => {
  const { form } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const print_ref = useRef(null);
  // const [form] = Form.useForm()
  const [re_arrange, set_re_arrange] = useState([]);
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;
  const from_date = Form.useWatch("from_date", form);
  const to_date = Form.useWatch("to_date", form);
  const [get_date, set_date_value] = useState(moment(accountingDate));
  const [print_loading, set_print_loading] = useState(false);
  const [download_loading, set_download_loading] = useState(false);

  const { toPDF, targetRef } = usePDF({
    filename: `gl_summary ${moment(from_date).format("DD/MM/YYYY")}-${moment(
      to_date
    ).format("DD/MM/YYYY")}.pdf`,
    page: { margin: Margin.MEDIUM, format: "A4" },
  });

  const handle_print = useReactToPrint({
    content: () => print_ref.current,
    documentTitle: "Print This Document",
  });

  const { items, loading } = useDynamicSelector("getGlAccountsSummary");
  const handle_close = () => {
    // set_re_arrange([])
    props.onCancel();
  };
  const get_first_day = (date) => {
    return moment(date).startOf("month").utcOffset("+05:30").toISOString();
  };
  const get_gl_summary = () => {
    let key = [{ key: "getGlAccountsSummary", loading: true }];
    let query = query_get_gl_accounts_summary;
    let variables = {
      from_date: from_date,
      to_date: moment(get_last_day(to_date)),
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const disabledDate = (current) => {
    return current && current > moment(accountingDate);
  };
  // const to_disabledDate = current => {
  //     return current && current > moment(accountingDate).format("DD/MM/YYYY");
  // };
  useEffect(() => {
    if (to_date && from_date) {
      get_gl_summary();
    }
  }, [from_date, to_date]);

  useEffect(() => {
    if (items?.length !== 0) {
      let re_generate_values = change_month_wise(items);
      set_re_arrange(re_generate_values);
    } else {
      showToast({
        type: "error",
        message:
          "There are no available statements for the previous and following months.",
      });
      set_re_arrange([]);
    }
  }, [items]);

  const handle_finish = (val, values) => {
    // set_re_arrange([])
  };

  useEffect(() => {
    if (accountingDate) {
      form.setFieldsValue({
        from_date: moment(accountingDate),
      });
      // set_re_arrange([])
    }
  }, [accountingDate]);

  const handle_changed_date = (value) => {
    set_date_value(value);
  };

  const handle_print_generate = () => {
    if (from_date && to_date) {
      set_print_loading(true);
      setTimeout(() => {
        handle_print();
        set_print_loading(false);
      }, 2000);
    }
  };

  const handle_download = () => {
    if (from_date && to_date) {
      set_download_loading(true);
      setTimeout(() => {
        toPDF();
        set_download_loading(false);
      }, Number(items?.length * 1500));
    }
  };

  return (
    <div>
      <Form layout="vertical" form={form} onFinish={handle_finish}>
        <Row gutter={24} justify={"center"}>
          <Col span={12}>
            <Form.Item
              label={t("From")}
              name={"from_date"}
              rules={[
                {
                  required: true,
                  message: "From month is required",
                },
              ]}
            >
              <DatePicker
                disabledDate={disabled_range}
                defaultValue={moment(accountingDate)}
                picker="month"
                placeholder="Select month"
                // value={get_date}
                // onChange={handle_changed_date}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("To")}
              name={"to_date"}
              rules={[
                {
                  required: true,
                  message: "To month is required",
                },
              ]}
            >
              <DatePicker
                // minDate={moment(accountingDate)}
                // maxDate={moment(accountingDate)}
                defaultPickerValue={moment(accountingDate)}
                disabledDate={(date) => to_disabled_range(date, from_date)}
                picker="month"
                placeholder="Select month"
              />
            </Form.Item>
          </Col>
          {/* <Col span={8} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <Button htmlType='submit' loading={loading} type='primary'>Generate</Button>
                    </Col> */}
        </Row>
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"40px"}>
          <Button
            onClick={handle_download}
            type="primary"
            htmlType="submit"
            loading={download_loading}
          >
            Download
          </Button>
          <Button
            onClick={handle_print_generate}
            type="primary"
            htmlType="submit"
            loading={print_loading}
          >
            Print
          </Button>
          <Button danger onClick={handle_close} type="primary">
            Close
          </Button>
        </HStack>
      </Form>
      <div style={{ position: "fixed", top: "10000vh" }}>
        <div ref={print_ref}>
          <GlStatementPDF
            to_date={get_last_day(to_date)}
            from_date={get_first_day(from_date)}
            re_arrange={re_arrange}
            f_date={from_date}
            t_date={to_date}
            accountingDate={accountingDate}
          />
        </div>
        <div ref={targetRef}>
          <GlStatementPDF
            to_date={get_last_day(to_date)}
            from_date={get_first_day(from_date)}
            re_arrange={re_arrange}
            f_date={from_date}
            t_date={to_date}
            accountingDate={accountingDate}
          />
        </div>
      </div>
    </div>
  );
};

export default GlStatementModal;
