import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Box, HStack, Button, Pressable } from "native-base";
import { DatePicker, Modal, Pagination, Space } from "antd";
import { Table } from "antd";
import {
  dynamicClear,
  dynamicRequest,
  mutation_delete_general_setting,
  query_get_all_general_settings,
  useDynamicSelector,
} from "../../../services/redux";
import { AiOutlineEdit } from "react-icons/ai";
import { Form as AntdForm } from "antd";
import SearchBox from "@views/components/ui/search_box/search_box";
import { DeleteFilled, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import GeneralSettingsDetails from "./general_settings_details";
import DeleteGeneralSettingModal from "./delete_general_setting_modal";

const GeneralSettingsList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = AntdForm.useForm();
  const general_settings_list = useDynamicSelector("getAllGeneralSettings");
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");

  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const [is_modal_open, set_modal_open] = useState(false);
  const [edit_type, set_edit_type] = useState("");
  const [action_items, set_action_items] = useState({});
  useEffect(() => {
    get_all_general_settings_list();
  }, []);

  const get_all_general_settings_list = () => {
    let key = [{ key: "getAllGeneralSettings", loading: true }];
    let query = query_get_all_general_settings;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_edit = (record) => {
    set_edit_type("edit");
    form.setFieldsValue({
      leniency_days: general_settings_list?.leniency_days,
      type: general_settings_list?.type,
    });
    set_modal_open(true);
    set_action_items(record);
  };

  let columns = [
    {
      title: t("table:leniency_days"),
      dataIndex: "leniency_days",
      key: "leniency_days",
      sortable: false,
      align: "center",
      width: "350px",
    },
    {
      title: t("table:type"),
      dataIndex: "type",
      key: "",
      sortable: false,
      align: "center",
      width: "350px",
      render: (data) => {
        if (data === "working_days") {
          return "Working Days";
        } else if (data === "calender_days") {
          return "Calender Days";
        }
      },
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      sortable: false,
      align: "center",
      width: "200px",
      type: "actions",
      width: 200,
      render: (record) => (
        <Space direction="row">
          <Pressable
            onPress={() => {
              handle_edit(record);
            }}
          >
            <EditOutlined style={{ cursor: "pointer", color: "blue" }} />
          </Pressable>
        </Space>
      ),
    },
  ];

  const handle_add_general_setting = () => {
    set_modal_open(true);
    set_action_items({});
  };

  const handle_close = () => {
    set_modal_open(false);
    form.resetFields("");
  };

  return (
    <Box width="100%" marginBottom="20px" overflow={"hidden"} mt="2px">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {t("general_settings")}
      </Box>

      <GeneralSettingsDetails
        get_all_general_settings_list={get_all_general_settings_list}
        close={handle_close}
        key={is_modal_open ? "modal-open" : "modal-closed"}
        type={edit_type}
        form={form}
        action_items={action_items}
      />
    </Box>
  );
};

export default GeneralSettingsList;
