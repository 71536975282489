import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";

import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import Modal from "@views/components/ui/modal";
import {
  amountFormat,
  formatDate,
  formatDisplayDate,
} from "./../../../../helpers/utils";
import SocietyDividendSettingDetails from "./society_dividend_setting_details";
import {
  createSocietyDividendSettingSelector,
  create_society_dividend_setting_clear,
} from "../../../../services/redux/slices/society_dividend_setting/society_dividend_setting_create";
import {
  getSocietyDividendSettingListSelector,
  get_society_dividend_settings,
} from "../../../../services/redux/slices/society_dividend_setting/society_dividend_setting_list";
import {
  dynamicRequest,
  query_accounting_date,
  useDynamicSelector,
} from "../../../../services/redux";
import moment from "moment";
import { usePageComponentAccess } from "../../../../helpers/auth";

const SocietyDividendSettingList = (props) => {
  const dispatch = useDispatch();
  const {
    status: societyDividendSettingCreateStatus,
    error: societyDividendSettingCreateError,
  } = useDynamicSelector("createDividendSetting");

  let {
    items,
    loading: getAllLoading,
    total_items,
  } = useSelector(getSocietyDividendSettingListSelector);

  const [actionItem, setActionItem] = useState({});
  const [query, setQuery] = useState({ society_id: props.society_id });
  const [item, setItem] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [actionType, setActionType] = useState("");
  const { t } = useTranslation();
  const { id } = useParams();
  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalVisible(true);
  };
  const handleModalClose = () => {
    setModalVisible(false);
  };

  let columns = [
    {
      header: t("table:effective_from"),
      dataIndex: "financial_year.from_financial_year",
      key: "effective_date",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:effective_to"),
      dataIndex: "financial_year.to_financial_year",
      key: "effective_date",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:dividend_percentage"),
      dataIndex: "dividend_percentage",
      key: "dividend_percentage",
      sortable: false,
      align: "left",
      flex: 1,
      //   formatDisplay: amountFormat,
    },
    {
      header: t("table:profit_amount"),
      dataIndex: "profit_amount",
      key: "profit_amount",
      sortable: false,
      align: "right",
      flex: 1,
      formatDisplay: amountFormat,
    },
    // {
    //   header: t("table:reserve_fund_percentage"),
    //   dataIndex: "reserve_fund_percentage",
    //   key: "reserve_fund_percentage",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   //   formatDisplay: amountFormat,
    // },
    // {
    //   header: t("table:coop_research_dev_percentage"),
    //   dataIndex: "coop_research_dev_percentage",
    //   key: "coop_research_dev_percentage",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   //   formatDisplay: amountFormat,
    // },
    // {
    //   header: t("table:coop_education_percentage"),
    //   dataIndex: "coop_education_percentage",
    //   key: "coop_education_percentage",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   //   formatDisplay: amountFormat,
    // },
    // {
    //   header: t("table:bdr_percentage"),
    //   dataIndex: "bdr_percentage",
    //   key: "bdr_percentage",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   //   formatDisplay: amountFormat,
    // },
    // {
    //   header: t("table:cgf_percentage"),
    //   dataIndex: "cgf_percentage",
    //   key: "cgf_percentage",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   //   formatDisplay: amountFormat,
    // },
    // {
    //   header: t("table:building_fund_percentage"),
    //   dataIndex: "building_fund_percentage",
    //   key: "building_fund_percentage",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   //   formatDisplay: amountFormat,
    // },
    // {
    //   header: t("table:honorarium_percentage"),
    //   dataIndex: "honorarium_percentage",
    //   key: "honorarium_percentage",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   //   formatDisplay: amountFormat,
    // },
    // {
    //   header: t("table:bonus_percentage"),
    //   dataIndex: "bonus_percentage",
    //   key: "bonus_percentage",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   //   formatDisplay: amountFormat,
    // },

    // {
    //   header: t("table:actions"),
    //   dataIndex: "",
    //   key: "actions",
    //   align: "center",
    //   type: "actions",
    //   width: 200,
    // actions: [
    //   {
    //     icon: <AiOutlineEdit />,
    //     iconSize: 20,
    //     iconColor: "primary.700",
    //     title: t("table:edit_society_product"),
    //     onPress: handleEdit,
    //   },
    //   {
    //     icon: <AiOutlineDelete />,
    //     iconSize: 20,
    //     iconColor: "red.700",
    //     title: t("table:delete_society_product"),
    //     onPress: handleDelete,
    //   },
    // ],
    // },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_profit_appropriation"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
      key: "Society Profit Appropriation Add",
    },
  ];

  useEffect(() => {
    if (societyDividendSettingCreateError) {
      showToast({
        type: "error",
        message: societyDividendSettingCreateError?.message,
      });
      handleModalClose();
      dispatch(create_society_dividend_setting_clear());
    } else if (societyDividendSettingCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("society_dividend_setting_created_successfully")}`,
      });
      dispatch(get_society_dividend_settings());
      handleModalClose();
      dispatch(create_society_dividend_setting_clear());
    }
  }, [societyDividendSettingCreateError, societyDividendSettingCreateError]);

  return (
    <>
      <Box w="100%" mt="10">
        <VStack variant={"scroll_fixed"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="MemberSettings"
              loading={getAllLoading}
              data={items || []}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_society_dividend_settings}
              query={query}
              headerActions={
                header_actions.filter((action) =>
                  usePageComponentAccess(action.key)
                ) || null
              }
              emptyMessage={t("table:empty_dividend_settings")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Modal
        isOpen={modalVisible}
        onClose={handleModalClose}
        header={
          actionType === "add"
            ? t("table:add_profit_appropriation")
            : t("edit_society_product")
        }
        component={
          <SocietyDividendSettingDetails
            id={id}
            source={actionItem}
            close={handleModalClose}
          />
        }
        width={990}
        // source={actionItem}
        group={"SocietyBoardMember"}
        size={"container"}
      />
    </>
  );
};
export default SocietyDividendSettingList;
