import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { HStack } from "native-base";
import Form from "@views/components/ui/antd_form";
import {
  dynamicClear,
  dynamicRequest,
  mutation_create_holiday,
  mutation_delete_holiday,
  mutation_update_general_settings,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../helpers/toast";
import { get_areas_query } from "../../../services/redux/slices/area/graphql";
import { commonDateFormat, getDayOfWeek } from "@functions";
import { lowerCase } from "lodash";
import { Form as AntdForm } from "antd";
import moment from "moment";
import { general_settings_types } from "../../../helpers/constants";

const GeneralSettingsDetails = (props) => {
  const { form, action_items } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const general_settings_list = useDynamicSelector("getAllGeneralSettings");

  const {
    status: general_setting_create_status,
    loading: general_setting_create_loading,
    error: general_setting_create_error,
  } = useDynamicSelector("updateGeneralSettings");

  useEffect(() => {
    if (general_setting_create_status === "Success") {
      showToast({
        type: "success",
        message: t("general_settings_updated"),
      });
      dispatch(dynamicClear("updateGeneralSettings"));
      props.close();
      props.get_all_general_settings_list();
    } else if (general_setting_create_error) {
      error_message_validate_toast(general_setting_create_error);
      dispatch(dynamicClear("updateGeneralSettings"));
    } else if (general_setting_create_error) {
      error_message_validate_toast(general_setting_create_error);
      dispatch(dynamicClear("updateGeneralSettings"));
    }
    form.setFieldsValue({
      leniency_days: general_settings_list?.leniency_days,
      type: general_settings_list?.type,
    });
  }, [
    general_setting_create_status,
    general_setting_create_error,
    general_settings_list,
  ]);

  const handle_submit = (values) => {
    if (action_items) {
      update_general_setting({
        id: action_items?.id,
        values,
      });
    } else {
      update_general_setting({ id: "", ...values });
    }
  };

  // same API for create and update
  const update_general_setting = ({ id, values }) => {
    let key = [{ key: "updateGeneralSettings", loading: true }];
    let query = mutation_update_general_settings;
    let variables = { data: { ...values, id } };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <AntdForm
      onFinish={handle_submit}
      form={form}
      width={"100%"}
      layout="vertical"
      className="general-settings"
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Number
            notShow={true}
            label={t("leniency_days")}
            field={`leniency_days`}
            rules={[
              {
                required: true,
                message: t("error:leniency_days_is_required"),
              },
            ]}
          />
        </Col>
        <Col span={12}>
          <Form.Select
            field={"type"}
            label={t("type")}
            options={general_settings_types}
            rules={[
              {
                required: true,
                message: t("error:type_is_required"),
              },
            ]}
          />
        </Col>
        {/* <Col span={12}>
            <Form.Select
              field={"holiday_type"}
              label={t("Holiday Type")}
              options={holiday_options}
              rules={[
                {
                  required: true,
                  message: t("error:holiday_type_is_required"),
                },
              ]}
            />
          </Col> */}
        {/* <Col span={12} style={{ marginTop: "26px" }}>
                        <Form.CheckBox
                            label={t("form:is_applicable_to_all_societies")}
                            field={`all_societies`}

                        // onChange={(event) => setShowAreaDropdown(event.target.checked)}
                        />
                    </Col> */}
        {/* {!showAreaDropdown && (
            <Col span={12}>
              <Form.Select
                field={"area_id"}
                label={t("area")}
                options={areas}
                rules={[
                  {
                    required: showAreaDropdown ? false : true,
                    message: t("error:area_is_required"),
                  },
                ]}
              />
            </Col>
          )} */}
      </Row>
      <Row gutter={24} justify="flex-end">
        <Form.Button
          colorScheme="primary"
          variant="outline"
          formErrorMessage={t("error:error_message")}
          isLoading={general_setting_create_loading}
        >
          {t("submit")}
        </Form.Button>
      </Row>
    </AntdForm>
  );
};

export default GeneralSettingsDetails;
