import { Table } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatDisplayDate } from "@helpers/utils";
import { amountFormat } from "./../../../helpers/utils";

const DividendHistory = (props) => {
  const { initialValues } = props;
  const { t } = useTranslation();
  let columns = [
    {
      title: t("table:from_date"),
      dataIndex: "from_date",
      key: "from_date",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDisplayDate(record);
      },
    },
    {
      title: t("table:to_date"),
      dataIndex: "to_date",
      key: "to_date",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDisplayDate(record);
      },
    },
    {
      title: t("table:share"),
      dataIndex: "share_amount",
      key: "share_amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (share_amount) => {
        return amountFormat(share_amount);
      },
    },
    {
      title: t("table:no_of_days"),
      dataIndex: "no_of_days",
      key: "dividend",
      sortable: false,
      align: "right",
      flex: 1,
    },
  ];

  return (
    <div>
      <Table
        width="100%"
        rowKey="id"
        loading={false}
        dataSource={initialValues?.log}
        columns={columns}
        hasSearch
        pagination={false}
      />
    </div>
  );
};

export default DividendHistory;
