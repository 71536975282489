import { gql } from "@apollo/client";

export const mutation_create_dividend_setting = gql`
  mutation createDividendSetting($data: dividend_setting_input) {
    createDividendSetting(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_dividend_settings = gql`
query getDividends(
  $financial_year: Int
  $status: String
  $page_number: Int
  $page_limit: Int
  $search_string: String
) {
  getDividends(
    financial_year: $financial_year
    status: $status
    page_number: $page_number
    page_limit: $page_limit
    search_string: $search_string
  ) {
    error
    total_dividend
    dividends {
      id
      status
      dividend_payout_id
      member_id
      name
      member_number
      dividend_amount
      total_overdue_amount
      adjustable_overdue_amount
      payable_dividend_amount
      log {
        share_amount
        from_date
        to_date
        no_of_days
      }
    }
    pagination {
      total_count
      page_limit
      page_number
    }
  }
}
`;

export const create_dividend_payout_mutation = gql`
 mutation dividendPayout(
  $data: dividend_payout_input
  $additional_data: additional_data_input
) {
  dividend_payout:dividendPayout(data: $data, additional_data: $additional_data) {
    id
    status
    error {
      status_code
      message
    }
  }
}`;

export const update_dividend_for_23_24 = gql`
mutation updateDividendFor23_24($url: String!, $dividend_percentage: Float) {
  updateDividendFor23_24(dividend_percentage: $dividend_percentage, url: $url) {
    id
    status
    error {
      status_code
      message
    }
  }
}
`;

export const get_23_24_excel_option_show =gql`
query getDividendPayableLogFor23To24{
  getDividendPayableLogFor23To24{
    id
    dividend_percentage
    url
    accounting_date
    created_date
  }
}
`;
