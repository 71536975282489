import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, Text, HStack, Pressable } from "native-base";
import { useTranslation } from "react-i18next";
import { AiOutlineEye } from "react-icons/ai";
import { amountFormat } from "@helpers/utils";
import DividendHistory from "./dividend_history";
import { get_23_24_excel_option_show, query_dividend_settings } from "../../../services/redux/slices/dividend_setting/dividend_setting";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../services/redux";
import { Button, Pagination, Table, Tag, Modal, Space, Input } from "antd";
import SelectFilter from "./../../components/modules/select_filter/select_filter";
import DividendPayoutDetails from "./dividend_payout_details";
import { showToast } from "@helpers/toast";
import { usePageComponentAccess } from "../../../helpers/auth";
import { countFormat } from "helpers/utils";
const FileUploadDividend = React.lazy(() => import("./file_upload_dividend"));
import { dividend_status, divident_sample_excel_file } from "helpers/constants";

const DividendList = () => {
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDividendOpen, setDividendModalOpen] = useState(false);
  const [open_dividend_file_upload_modal, set_open_dividend_file_upload_modal] =
    useState(false);
  const [financial_year, set_financial_year] = useState(null);
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const [already_selected_rows, set_already_selected_rows] = useState([]);
  const [already_selected_data, set_already_selected_data] = useState([]);
  const [filter_status, set_filter_status] = useState(null);
  const [search_string, set_search_string] = useState(null);
  
  // const { debounced_value, is_pending } = useTransitionDebounce(
  //   search_string,
  //   500
  // );

  const items = useDynamicSelector("getDividends");
  const {url: upload_option_show} = useDynamicSelector("getDividendPayableLogFor23To24");
  const { status, error } = useDynamicSelector("dividend_payout");

  useEffect(() => {
    if (items?.error) {
      showToast({ type: "error", message: items?.error });
    }
  }, [items]);

  useEffect(()=>{
    if(!financial_year)
      dispatch(dynamicClear("getDividends"));
  },[financial_year])

  function getYearsFrom2023() {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 2022; year <= currentYear - 1; year++) {
      years.push({
        name: `${year} - ${year + 1}`,
        id: year,
      });
    }
    return years;
  }

  useEffect(() => {
    if (items?.error) {
      showToast({ type: "error", message: items?.error });
      dispatch(dynamicClear("getDividends"));
    }
  }, [items]);

  const currentYear = getYearsFrom2023();

  const handleModalOpen = (item) => {
    setActionItem(item);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleDividendModalOpen = () => {
    setDividendModalOpen(true);
  };

  const handleDividendModalClose = () => {
    setDividendModalOpen(false);
  };
  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.name}{" "}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member_number ? value?.member_number : ""}
        </Text>
      </VStack>
    );
  };

  const dividend_settings_list = () => {
    let key = [{ key: "getDividends", loading: true }];
    dispatch(
      dynamicRequest(
        key,
        query_dividend_settings,
        {
          financial_year: financial_year,
          page_number,
          page_limit,
          status: filter_status,
          search_string,
        },
        "Q"
      )
    );
  };

  useEffect(() => {
    if (financial_year && (filter_status || true)) {
      dividend_settings_list();
    } else {
      showToast({ type: "error", message: "Please select the year" });
    }
  }, [financial_year, filter_status]);

  useEffect(()=>{
    if(financial_year == "2023"){
      // for file upload button show
      get_upload_show_condition();
    }
  },[financial_year])

  const get_upload_show_condition = ()=>{
    let key = [{ key: "getDividendPayableLogFor23To24", loading: true }];
    let query = get_23_24_excel_option_show;
    let variables={};
    dispatch(
      dynamicRequest(key,query,variables)
    );
  }

  const memberDisplayFormat = (value) => {
    return (
      <a target={"_blank"} href={`/society-member/${value?.id}`}>
        {formatMember(value)}
      </a>
    );
  };

  useEffect(() => {
    if (status === "Success") {
      showToast({
        type: "success",
        message: t("Dividend Payout successfully"),
      });
      set_already_selected_rows([])
      dividend_settings_list();
      handleDividendModalClose();
      dispatch(dynamicClear("dividend_payout"));
    } else if (error) {
      showToast({
        type: "error",
        message: error?.message,
      });
      handleDividendModalClose();
      dispatch(dynamicClear("dividend_payout"));
    }
  }, [error, status]);

  let columns = [
    {
      title: t("table:member"),
      dataIndex: "",
      key: "member",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => memberDisplayFormat(record),
    },
    {
      title: t("table:dividend_amount"),
      dataIndex: "dividend_amount",
      key: "payable_dividend_amount",
      render: (record) => amountFormat(record),
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:overdue_amount"),
      dataIndex: "total_overdue_amount",
      key: "total_overdue_amount",
      sortable: false,
      render: (record) => amountFormat(record),
      align: "right",
      flex: 1,
    },
    {
      title: t("table:payable_dividend_amount"),
      dataIndex: "dividend_amount",
      key: "dividend_amount",
      sortable: false,
      render: (record) => amountFormat(record),
      align: "right",
      flex: 1,
    },
    {
      title: t("table:status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      sortable: false,
      render: (status) => {
        return (
          <Tag
            color={status === "Paid" ? "green" : "red"}
            key={status}
            style={{
              width: "80px",
              textAlign: "center",
            }}
          >
            <b>{status === "Paid" ? "Paid" : "Not Paid"}</b>
          </Tag>
        );
      },
      flex: 1,
    },
    {
      title: t("table:details"),
      dataIndex: "",
      key: "actions",
      align: "center",
      width: 100,
      render: (record) => {
        return (
          <AiOutlineEye
            color="#683fe9"
            className="cursor-pointer"
            size="20"
            onClick={() => {
              handleModalOpen(record);
            }}
            />
        );
      },
    },
  ];

  const fetch_data = (page_number, page_limit) => {
    let key = [{ key: "getDividends", loading: true }];
    let query = query_dividend_settings;
    let variables = {
      financial_year,
      page_number,
      page_limit,
      status:filter_status,
      search_string,
    };
    dispatch(dynamicRequest(key, query, variables));
    set_page_number(page_number);
    set_page_limit(page_limit);
  };

  const handle_dividend_file_upload = () => {
    set_open_dividend_file_upload_modal(true);
  };

  const dividend_upload_close = () => {
    set_open_dividend_file_upload_modal(false);
  };

  const download_sample_excel_file = () => {
    window.open(divident_sample_excel_file);
  };

  return (
    <>
      <Box width="100%" marginBottom="20px" overflow={"hidden"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
            marginBottom: "10px",
          }}
        >
          {t("Dividend")}
        </Box>
        <Space space={2}>
          <Box width={"200px"}>
            <SelectFilter
              options={currentYear}
              setField={set_financial_year}
              placeholder={"Year"}
            />
          </Box>
          <Box width={"200px"}>
            <SelectFilter
              options={dividend_status}
              setField={set_filter_status}
              placeholder={"Status"}
            />
          </Box>
        </Space>
        <HStack justifyContent={"flex-end"} mb="4" space={2}>
          <HStack space={2}>
            <Input
              placeholder="Search"
              onChange={(e) => set_search_string(e?.target.value)}
              style={{ width: 200 }}
              allowClear
              value={search_string}
            />
            
            {financial_year == "2023" && !upload_option_show && (
              <>
                <Button onClick={download_sample_excel_file} type="outlined">
                  Sample Excel
                </Button>
                <Button onClick={handle_dividend_file_upload} type="primary">
                  Upload
                </Button>
              </>
            )}
          </HStack>
          {usePageComponentAccess("Dividend List Payout") && (
            <Box>
              <Button
                onClick={handleDividendModalOpen}
                type="primary"
                disabled={!already_selected_rows?.length > 0}
              >
                Payout
              </Button>
            </Box>
          )}
        </HStack>

        <Table
          loading={items?.loading}
          rowKey="id"
          dataSource={items?.dividends}
          columns={columns}
          rowSelection={{
            selectedRowKeys: already_selected_rows,
            onChange: (keys, value) => {
              set_already_selected_rows(keys);
              set_already_selected_data(value);
            },
            getCheckboxProps: (record) => ({
              disabled: record.status === "Paid",
            }),
            selections: [
              Table.SELECTION_ALL,
              Table.SELECTION_NONE,
              Table.SELECTION_INVERT,
            ],
          }}
          pagination={false}
        />

        <HStack space={5} justifyContent={"flex-end"} mt={"10px"}>
          <Pagination
            total={items?.pagination?.total_count}
            showSizeChanger={true}
            page_number={page_number}
            hideOnSinglePage={false}
            onChange={fetch_data}
            showTotal={(total, range) =>
              `Total ${countFormat(total)} Dividends`
            }
          />
        </HStack>
        <Modal
          open={modalOpen}
          onCancel={handleModalClose}
          title={`${actionItem?.name} - ${actionItem?.member_number}`}
          width={"900px"}
          footer={false}
          destroyOnClose
        >
          <DividendHistory
            close={handleModalClose}
            initialValues={actionItem}
          />
        </Modal>
        
        <Modal
          open={modalDividendOpen}
          onCancel={handleDividendModalClose}
          title={`Dividend Payout`}
          width={"900px"}
          footer={false}
          destroyOnClose
        >
          <DividendPayoutDetails
            financialYear={financial_year}
            close={handleDividendModalClose}
            initialValues={actionItem}
            member_id_list={already_selected_data}
          />
        </Modal>

        <Modal
          open={open_dividend_file_upload_modal}
          onCancel={dividend_upload_close}
          title={`Dividend Upload`}
          width={"500px"}
          footer={false}
          destroyOnClose
        >
          <Suspense fallback={<div>Loading...</div>}>
            <FileUploadDividend
              financialYear={financial_year}
              close={dividend_upload_close}
              fetch_data={fetch_data}
            />
          </Suspense>
        </Modal>
      </Box>
    </>
  );
};
export default DividendList;
