import React, { memo, useMemo, useRef } from "react";
import { Button, Pagination, Table, Tooltip, Typography } from "antd";
import { useState, useEffect } from "react";
import { Box, HStack, Input, Pressable, Text, VStack } from "native-base";
import { amountFormat } from "@helpers/utils";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  getDemandCollectionsListSelector,
  getSocietyDepositProductListSelector,
  getSuretyLoanProductListSelector,
  get_society_deposit_product_list,
  get_surety_loan_products,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import RecordCollection from "./record_collection";
import Modal from "@views/components/ui/modal";
import { DeleteFilled } from "@ant-design/icons";
import AddRecoveryDetails from "../demand_generation/add_recovery_details";
import { showToast } from "@helpers/toast";
import SearchBox from "@views/components/ui/search_box/search_box";
import DemandRecoveryAmountInput from "./demand_recovery_amount_input";
import {
  dynamicRequest,
  get_all_rd_products,
  get_pay_officer_dueby_department_query,
  get_pay_officer_dueby_query,
  get_pay_officer_dueby_subdepartment_query,
} from "../../../services/redux";
import SelectFilter from "./../../components/modules/select_filter/select_filter";
import { get_demand_query } from "../../../services/redux/slices/demand/graphql";
import { usePageComponentAccess } from "../../../helpers/auth";
import { calculateAmountTotal } from "@helpers/functions";
import { Tab } from "react-tab-view";
import { useParams } from "react-router-dom";
import { get_sub_department } from "services/redux/slices/department/graphql";
import CollectionAmountSelectionModal from "./amount_select_modal";

const { Column, ColumnGroup } = Table;

const DemandCollectionList = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const society_id = localStorage.getItem("society_id");
  const {
    sub_department_id,
    id,
    selected_amount,
    set_selected_amount,
    old_balance,
    collection_amount_modal_ref,
  } = props;

  const [member_name, setMemberName] = useState("");
  const [is_delete_modal_open, setIsDeleteModalOpen] = useState(false);
  const [total_recovery_amount, setTotalRecoveryAmount] = useState(0);
  const [is_add_recovery_model_open, setIsAddRecoveryModalOpen] =
    useState(false);
  const [member_id, setMemberId] = useState("");
  const [default_data, setDefaultData] = useState([]);
  const [data, setData] = useState([]);
  const [collection_modal_open, setCollectionModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [isCollected, setIsCollected] = useState("not_collected");
  const [currentList, setCurrentList] = useState([]);
  const [totalValues, setTotalValues] = useState({});
  const [recurring_deposits, set_recurring_deposits] = useState([]);
  const demand = useDynamicSelector("demand");
  let { items: deposit_products } = useSelector(
    getSocietyDepositProductListSelector
  );
  let { items: loan_products } = useSelector(getSuretyLoanProductListSelector);
  const { loading: collection_loading, total_items } = useSelector(
    getDemandCollectionsListSelector
  );
  const { department } = useDynamicSelector("getSubDepartment");
  const payofficer_dueby_dept_items = useDynamicSelector(
    "getPayofficerDuebysDepartment"
  );
  const payofficer_dueby_sub_dept_items = useDynamicSelector(
    "getPayofficerDuebys"
  );
  const { status: createStatus, error: createError } = useDynamicSelector(
    "create_demand_collection"
  );

  useEffect(() => {
    let parsed = demand?.demand_output && JSON?.parse(demand?.demand_output);
    let ordered = parsed
      ?.map((deposit) => deposit?.recurring_deposit)
      ?.map((rd) => rd?.[0]);
    set_recurring_deposits(parsed);
  }, [demand]);

  useEffect(() => {
    if (
      payofficer_dueby_sub_dept_items?.items?.[0]?.balance_amount > 0 &&
      payofficer_dueby_dept_items?.items?.[0]?.balance_amount > 0
    ) {
      collection_amount_modal_ref?.current?.set_is_modal_visible(true);
    } else if (
      payofficer_dueby_sub_dept_items?.items?.[0]?.balance_amount > 0
    ) {
      set_selected_amount({
        amount: payofficer_dueby_sub_dept_items?.items?.[0]?.balance_amount,
        source: "subdepartment",
      });
    } else if (payofficer_dueby_dept_items?.items?.[0]?.balance_amount > 0) {
      set_selected_amount({
        amount: payofficer_dueby_dept_items?.items?.[0]?.balance_amount,
        source: "department",
      });
    }
  }, [payofficer_dueby_sub_dept_items, payofficer_dueby_dept_items]);

  const handleAddCollection = () => {
    if (
      selected_amount?.amount >= total_recovery_amount ||
      old_balance >= total_recovery_amount
    ) {
      setCollectionModalOpen(true);
    } else {
      showToast({
        type: "error",
        message: t("error:collection_amount_error_message"),
      });
    }
  };
  const handleAddCollectionClose = () => {
    setCollectionModalOpen(false);
  };

  const { items: recurring_deposit_products } = useDynamicSelector(
    "getRecurringDepositProducts"
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        let key = [{ key: "getSubDepartment", loading: true }];
        let query = get_sub_department;
        let variables = {
          id: sub_department_id,
        };
        dispatch(dynamicRequest(key, query, variables));
        get_department_amount("department");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [sub_department_id, dispatch]);

  const get_department_amount = async (id_type) => {
    let key = [
      {
        key: "getPayofficerDuebysDepartment",
        loading: true,
      },
    ];
    let query = get_pay_officer_dueby_department_query;

    let variables = {
      sub_department_id: department?.id,
      type: "payofficer",
      society_id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getOneDemand = () => {
    let key = [{ key: "demand", loading: true }];
    let query = get_demand_query;
    let variables = {
      json: {
        id: id,
        search_string: search,
        page_number: page_number,
        page_limit: page_limit,
        is_colleted: isCollected === "collected" ? true : false,
      },
    };

    dispatch(dynamicRequest(key, query, variables));
  };
  const getRdLoanProductList = () => {
    let key = [{ key: "getRecurringDepositProducts", loading: true }];
    let query = get_all_rd_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };
  const handleSearch = (search_txt) => {
    setSearch(search_txt);
    setPageNumber(1);
    setPageLimit(10);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDeleteModalOpen = (record) => {
    setMemberId(record?.id);
    setMemberName(record?.name);
    setIsDeleteModalOpen(true);
  };

  const handleAddRecoveryModalClose = () => {
    setIsAddRecoveryModalOpen(false);
    dispatch(dynamicClear("getOneMemberDemandGeneration"));
  };
  const handleAddRecoveryModalOpen = (value) => {
    setIsAddRecoveryModalOpen(true);
  };
  const handleDelete = () => {
    const filteredArray = default_data?.filter((item) => item.id !== member_id);

    setDefaultData(filteredArray);
    handleDeleteModalClose();
    // dispatch(dynamicSet("is_delete_column", { cell_changed: true }));
  };
  const handleOnChange = (e, record) => {
    const newValue = e;
    const updatedData = default_data.map((item) =>
      item.id === record.id
        ? { ...item, recovery_amount: Number(newValue) }
        : item
    );
    setDefaultData(updatedData);

    let total_collection_amount = 0;

    total_collection_amount = updatedData?.reduce((sum, item) => {
      let recoveryAmount = 0;

      // This condition seems to be problematic
      if (!item.collection_amount) {
        recoveryAmount = item.recovery_amount
          ? Number(item.recovery_amount)
          : 0;
      }
      return sum + Number(recoveryAmount);
    }, 0);
    setTotalRecoveryAmount(total_collection_amount);
  };

  const calculateTotalValue = (record) => {
    let deposits = record?.deposits || record?.deposit_demands;
    let loans = record?.surety_loans || record?.loan_demands;

    let total = deposits?.reduce((sum, deposit) => {
      const amount =
        Number(deposit?.amount ? deposit?.amount : deposit?.current_amount) ||
        0;
      const arrearAmount =
        Number(deposit?.arrear_amount || 0) +
        Number(deposit?.current_month_balance || 0);
      return Number(sum) + (Number(amount) + Number(arrearAmount));
    }, 0);
    record?.recurring_deposit?.forEach((rd) => {
      total += Number(rd.arrear_amount || 0) + Number(rd.amount || 0);
    });
    loans?.forEach((loan) => {
      // if (loan?.surety_loan_demands) {
      //   loan.surety_loan_demands?.forEach((demand) => {
      //     total +=
      //       Number(demand.monthly_principal || 0) +
      //       Number(demand.interest || 0) +
      //       Number(demand.penal_interest || 0);
      //   });
      // } else {
      total +=
        Number(loan?.monthly_principal_payable || 0) +
        Number(loan?.interest_payable || 0) +
        Number(loan?.penal_interest_payable || 0) +
        Number(loan?.arrear_penal_interest_payable || 0) +
        Number(loan?.arrear_interest_payable || 0) +
        Number(loan?.arrear_principal_payable || 0) +
        Number(loan?.decree_interest || 0);
      // }
    });

    return total;
  };
  const total_principal = (loan) => {
    let principal = 0;
    let degree_interest = 0;
    let monthly_principal = 0;
    principal =
      loan?.surety_loan_demands?.[0]?.monthly_principal >
      loan?.surety_loan_demands?.[0]?.current_principal_balance
        ? Number(loan?.surety_loan_demands?.[0]?.current_principal_balance) -
          Number(loan?.surety_loan_demands?.[0]?.arrear_monthly_principal)
        : loan?.surety_loan_demands?.[0]?.monthly_principal;
    degree_interest = loan?.surety_loan_demands?.[0]?.decree_interest;
    monthly_principal = loan?.surety_loan_demands?.[0]?.monthly_principal;
    if (degree_interest === 0) {
      return principal;
    } else {
      return monthly_principal;
    }
  };

  const get_principal = (loan) => {
    let principal = 0;
    let degree_interest = 0;
    let monthly_principal = 0;
    principal =
      loan?.surety_loan_demands?.[0]?.monthly_principal >
      loan?.surety_loan_demands?.[0]?.current_principal_balance
        ? Number(loan?.surety_loan_demands?.[0]?.current_principal_balance) -
          Number(loan?.surety_loan_demands?.[0]?.arrear_monthly_principal)
        : loan?.surety_loan_demands?.[0]?.monthly_principal;
    degree_interest = loan?.surety_loan_demands?.[0]?.decree_interest;
    monthly_principal = loan?.surety_loan_demands?.[0]?.monthly_principal;
    if (degree_interest === 0) {
      return principal ? amountFormat(principal) : null;
    } else {
      return monthly_principal ? amountFormat(monthly_principal) : null;
    }
  };

  const calculateTotalDemandValue = (record) => {
    let deposits = record?.deposits;
    let loans = record?.surety_loans;
    let member_due = record?.member_duetos;

    let total = deposits?.reduce((sum, deposit) => {
      const amount = Number(deposit?.amount || 0);
      const arrearAmount =
        Number(deposit?.arrear_amount || 0) +
        Number(deposit?.current_month_balance || 0);

      return sum + amount + arrearAmount;
    }, 0);
    record?.recurring_deposit?.forEach((rd) => {
      total += Number(rd.arrear_amount || 0) + Number(rd.amount || 0);
    });
    loans?.forEach((loan) => {
      if (loan?.surety_loan_demands) {
        loan.surety_loan_demands?.forEach((demand) => {
          total +=
            Number(total_principal(loan) || 0) +
            Number(demand.interest || 0) +
            Number(demand.penal_interest || 0) +
            Number(demand.arrear_monthly_principal || 0) +
            Number(demand.arrear_interest || 0) +
            Number(demand.arrear_penal_interest || 0) +
            Number(demand.decree_interest || 0);
        });
      }
    });

    member_due?.forEach((dueto) => {
      total += dueto?.balance_amount || 0;
    }, 0);

    return total;
  };

  useEffect(() => {
    getOneDemand();
  }, [id, isCollected, page_number, page_limit, search]);

  useEffect(() => {
    let totalDemand = calculateAmountTotal({
      data: currentList,
      location: "generated_demand_amount",
    });
    let totalArearPrinciple = calculateAmountTotal({
      data: currentList,
      location: "generated_demand_amount",
    });
    setTotalValues({ totalDemand, totalArearPrinciple });
  }, [currentList]);

  useEffect(() => {
    getRdLoanProductList();
    dispatch(get_society_deposit_product_list({ society_id: society_id }));
    dispatch(get_surety_loan_products({ society_id: society_id }));
  }, []);

  useEffect(() => {
    if (demand?.demand_collections?.length && data?.length) {
      const mergedArray = data.map((obj1) => ({
        member_id: obj1.id,
        ...obj1,

        ...(demand?.demand_collections.find(
          (obj2) => obj2.member_id === obj1.id
        ) || {}),
      }));

      // Include unmatched elements from array2
      const unmatchedArray2 = demand?.demand_collections.filter(
        (obj2) => !mergedArray.some((obj) => obj.member_id === obj2.member_id)
      );
      mergedArray.push(
        ...unmatchedArray2.map((value) => {
          if (value.line_items) {
            const parsedLineItems = JSON.parse(value.line_items);
            value = {
              ...value,
              deposits: parsedLineItems?.[0]?.deposit_demands,
              surety_loans: parsedLineItems?.[0]?.loan_demands,
              generated_demand_amount: value.generated_demand_amount,
            };
          }
          return value;
        })
      );
      setDefaultData(mergedArray);
    } else if (demand?.demand_collections?.length) {
      const result = demand?.demand_collections.map((item) => {
        if (item.line_items) {
          const parsedLineItems = JSON.parse(item.line_items);

          item = {
            ...item,
            deposits: parsedLineItems?.[0]?.deposit_demands,
            surety_loans: parsedLineItems?.[0]?.loan_demands,
            generated_demand_amount: calculateTotalValue(parsedLineItems?.[0]),
          };
        }
        return item;
      });
      setDefaultData(result);
    } else if (data?.length) {
      setDefaultData(data);
    } else if (!demand?.demand_collections?.length && !data?.length) {
      setDefaultData([]);
    }
  }, [demand?.demand_collections, data]);
  useEffect(() => {
    if (demand?.id) {
      let demand_items = JSON.parse(demand?.demand_output)?.map((x) => {
        return {
          ...x,
          recovery_amount: calculateTotalDemandValue(x),
          generated_demand_amount: calculateTotalDemandValue(x),
        };
      });
      setData(demand_items);
    }
  }, [demand]);

  useEffect(() => {
    let total_collection_amount = 0;

    total_collection_amount = default_data?.reduce((sum, item) => {
      let recoveryAmount = 0;

      if (!item.collection_amount) {
        recoveryAmount = item.recovery_amount
          ? Number(item.recovery_amount)
          : 0;
      } else {
        recoveryAmount = item.collection_amount
          ? Number(item.collection_amount)
          : 0;
      }
      return sum + Number(recoveryAmount);
    }, 0);

    setTotalRecoveryAmount(total_collection_amount);
  }, [default_data]);

  useEffect(() => {
    if (createError) {
      showToast({
        type: "error",
        message: createError?.message?.includes("Couldn't approve")
          ? createError?.message
          : "Something went wrong!",
      });
      dispatch(dynamicClear("create_demand_collection"));
      handleAddCollectionClose();
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Bulk receipt request created successfully")}`,
      });

      handleAddCollectionClose();
      dispatch(dynamicClear("create_demand_collection"));
    }
  }, [createStatus, createError]);

  const columns = [
    <Column
      title="Member Number"
      dataIndex=""
      summery_key=""
      key="member_number"
      width={100}
      fixed="left"
      align="left"
      flex={1}
      render={(record) => {
        return record?.member_number || record?.member?.member_number;
      }}
    />,
    <Column
      title="Name"
      dataIndex=""
      key="name"
      summery_key=""
      width={400}
      fixed="left"
      align="left"
      flex={1}
      render={(record) => {
        return (
          <>
            {record?.member_id === "new" ? (
              <Box>
                <Text
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {record?.name || record?.member?.name}
                </Text>
              </Box>
            ) : (
              <Box>
                <Text>{record?.name || record?.member?.name}</Text>
              </Box>
            )}
          </>
        );
      }}
    />,
    <Column
      title="Total Demand"
      dataIndex="generated_demand_amount"
      key="total"
      align="right"
      fixed="left"
      summery_key="generated_demand_amount"
      render={(record) => {
        return amountFormat(record);
      }}
    />,
    <Column
      title="Total Demand Recovery"
      dataIndex=""
      key="total"
      align="right"
      fixed="left"
      summery_key="collection_amount"
      width={100}
      render={(record) => {
        return (
          <Box>
            <DemandRecoveryAmountInput
              record={record}
              handleOnChange={handleOnChange}
            />
          </Box>
        );
      }}
    />,
    <Column
      title="Actions"
      dataIndex=""
      key="name"
      width={100}
      fixed="left"
      align="left"
      flex={1}
      render={(record) => {
        return (
          <HStack>
            {record?.collection_amount > 0
              ? ""
              : usePageComponentAccess("Society Demand Collection Action") && (
                  <Pressable
                    onPress={() => {
                      handleDeleteModalOpen(record);
                    }}
                  >
                    <Typography.Link>
                      <Tooltip title="Delete">
                        <DeleteFilled
                          style={{
                            marginLeft: 20,
                            color: "#FF0000",
                          }}
                        />
                      </Tooltip>
                    </Typography.Link>
                  </Pressable>
                )}
          </HStack>
        );
      }}
    />,
    loan_products?.map((product) => (
      <ColumnGroup key={product?.name} title={product ? product?.name : "SL"}>
        <Column
          title={t("table:arrear_principal")}
          dataIndex="surety_loans"
          key={`${product?.id}-arrear_principal`}
          align="right"
          render={(suretyLoans) => {
            let principal = 0;
            suretyLoans.forEach((loan) => {
              if (loan.surety_loan_product?.id === product?.id) {
                principal +=
                  loan?.surety_loan_demands?.[0]?.arrear_monthly_principal;
              }
            });
            return principal ? amountFormat(principal) : "-";
          }}
        />{" "}
        <Column
          title={t("table:principal")}
          dataIndex="surety_loans"
          key={`${product?.id}-principal`}
          align="right"
          render={(suretyLoans) => {
            let principal = 0;
            // let degree_interest = 0;
            // let monthly_principal = 0
            suretyLoans.forEach((loan) => {
              if (loan.surety_loan_product?.id === product?.id) {
                principal = get_principal(loan);
              }
            });
            // suretyLoans.forEach((loan) => {
            //   if (loan?.surety_loan_product?.id === product?.id) {
            //     degree_interest += loan.surety_loan_demands?.[0]?.decree_interest;
            //     monthly_principal += loan?.surety_loan_demands?.[0]?.monthly_principal;
            //   }
            // });
            // if (degree_interest === 0) {
            //   return principal ? amountFormat(principal) : "-";
            // } else {
            //   return monthly_principal ? amountFormat(monthly_principal) : "-";
            // }
            return principal;
          }}
        />
        <Column
          title={t("table:arrear_interest")}
          dataIndex="surety_loans"
          key={`${product?.id}-arrear_interest`}
          align="right"
          render={(suretyLoans) => {
            let interest = 0;
            suretyLoans.forEach((loan) => {
              if (loan?.surety_loan_product?.id === product?.id) {
                interest += loan.surety_loan_demands?.[0]?.arrear_interest;
              }
            });
            return interest ? amountFormat(interest) : "-";
          }}
        />{" "}
        <Column
          title={t("table:interest")}
          dataIndex="surety_loans"
          key={`${product?.id}-interest`}
          align="right"
          render={(suretyLoans) => {
            let interest = 0;
            let principal = 0;
            suretyLoans.forEach((loan) => {
              principal += loan?.surety_loan_demands?.[0]?.monthly_principal;
              if (loan?.surety_loan_product?.id === product?.id) {
                if (
                  loan?.surety_loan_demands?.[0]?.fixed_demand_amount !== 0 &&
                  loan?.surety_loan_demands?.[0]?.fixed_demand_amount
                ) {
                  interest +=
                    Number(
                      loan?.surety_loan_demands?.[0]?.fixed_demand_amount || 0
                    ) - Number(principal || 0);
                } else {
                  interest += Number(loan.surety_loan_demands?.[0]?.interest);
                }
              }
            });
            return interest ? amountFormat(interest) : "-";
          }}
        />
        <Column
          title={t("table:decree_interest")}
          dataIndex="surety_loans"
          key={`${product?.id}-decree_interest`}
          align="right"
          render={(suretyLoans) => {
            let interest = 0;
            suretyLoans.forEach((loan) => {
              if (loan?.surety_loan_product?.id === product?.id) {
                interest += loan.surety_loan_demands?.[0]?.decree_interest;
              }
            });
            return interest ? amountFormat(interest) : "-";
          }}
        />
        <Column
          title={t("table:arrear_penal_interest")}
          dataIndex="surety_loans"
          key={`${product?.id}-arrear-penal-interest`}
          align="right"
          render={(suretyLoans) => {
            let penalInterest = 0;
            suretyLoans.forEach((loan) => {
              if (loan?.surety_loan_product?.id === product?.id) {
                penalInterest +=
                  loan.surety_loan_demands?.[0]?.arrear_penal_interest;
              }
            });
            return penalInterest ? amountFormat(penalInterest) : "-";
          }}
        />{" "}
        ;
        <Column
          title={t("table:penal_interest")}
          dataIndex="surety_loans"
          key={`${product?.id}-penal-interest`}
          align="right"
          render={(suretyLoans) => {
            let penalInterest = 0;
            suretyLoans.forEach((loan) => {
              if (loan?.surety_loan_product?.id === product?.id) {
                penalInterest += loan.surety_loan_demands?.[0]?.penal_interest;
              }
            });
            return penalInterest ? amountFormat(penalInterest) : "-";
          }}
        />
      </ColumnGroup>
    )),
    deposit_products?.map((depositName, i) => (
      <ColumnGroup key={`deposits${i}`} title={depositName?.name}>
        <Column
          title={t("table:amount")}
          dataIndex="deposits"
          key={`${depositName?.name} - amount`}
          align="right"
          render={(record) => {
            const deposit = record?.find(
              (deposit) => deposit.deposit_product?.id === depositName?.id
            );
            const amount = (deposit?.amount || deposit?.current_amount) ?? 0;
            // const arrearAmount = deposit?.arrear_amount ?? 0;
            // const totalAmount = amount + arrearAmount;
            return amount !== 0 ? amountFormat(amount) : "-";
          }}
        />
        <Column
          title={t("table:arrear_amount")}
          dataIndex="deposits"
          key={`${depositName?.name} -arrear`}
          align="right"
          render={(record) => {
            if (!record) return "-";
            const deposit = record?.find(
              (deposit) => deposit.deposit_product?.id === depositName?.id
            );

            // Directly check if amounts are zero
            const arrearAmount =
              Number(deposit?.arrear_amount ?? 0) +
              Number(deposit?.current_month_balance ?? 0);

            return arrearAmount !== 0 ? amountFormat(arrearAmount) : "-";
          }}
        />
      </ColumnGroup>
    )),
    recurring_deposit_products?.map((depositName, id) => (
      <ColumnGroup
        key={`recurring_deposits_${depositName?.id}`}
        title={depositName?.name}
      >
        {/* Column for Amount */}
        <Column
          title={t("table:amount")}
          dataIndex="recurring_deposit"
          key={`${depositName?.id} - amount`}
          align="right"
          render={(record) => {
            // Find the matching deposit for the current depositName
            const deposit = record?.find(
              (deposit) =>
                deposit.recurring_deposit_product?.id === depositName?.id
            );

            // Ensure amount is a number, defaulting to 0 if undefined
            const amount = Number(deposit?.amount) || 0;

            // Return formatted amount, avoiding NaN
            return amount ? amountFormat(amount) : "-";
          }}
        />

        {/* Column for Arrear Amount */}
        <Column
          title={t("table:arrear_amount")}
          dataIndex="recurring_deposit"
          key={`${depositName?.name} -arrear`}
          align="right"
          render={(record) => {
            // Find the matching deposit for the current depositName
            const deposit = record?.find(
              (deposit) =>
                deposit.recurring_deposit_product?.id === depositName?.id
            );

            // Safely handle addition of arrear_amount and current_amount
            const arrearAmount =
              (Number(deposit?.arrear_amount) || 0) +
              (Number(deposit?.current_amount) || 0);

            // Return formatted arrear amount, avoiding NaN
            return arrearAmount !== 0 ? amountFormat(arrearAmount) : "-";
          }}
        />
      </ColumnGroup>
    )),

    <Column
      title={"Dueto"}
      dataIndex=""
      key={"dueto"}
      align="right"
      render={(record) => {
        let totalBalance = record?.member_duetos?.reduce((dueto_sum, dueto) => {
          return dueto_sum + (dueto?.balance_amount || 0);
        }, 0);
        return totalBalance !== 0 ? amountFormat(totalBalance) : "-";
      }}
    />,
    // <SuretyLoanDynamicHeader
    //   surety_loan_products_name={surety_loan_products_name}
    // />,
    // <Column
    //   title={deposit_product?.code}
    //   dataIndex="recurring_deposit"
    //   key={deposit_product?.id}
    //   align="right"
    //   render={(record) => {
    //     const deposit = record?.find(
    //       (rd) => rd.recurring_deposit_product?.id === deposit_product?.id
    //     );
    //     const amount = deposit?.amount ?? 0;
    //     const arrearAmount = deposit?.arrear_amount ?? 0;
    //     const totalAmount = amount + arrearAmount;
    //     return amountFormat(totalAmount);
    //   }}
    // />
    // <DepositDynamicHeader deposit_products_name={deposit_products_name} />,
  ];

  const collection_options = [
    { id: "collected", name: "Collected" },
    { id: "not_collected", name: "Not Collected" },
  ];

  return (
    <Box flex={1} mt="4" overflow={"hidden"}>
      <VStack space="5">
        <>
          <HStack alignItems={"center"} space={"6"}>
            <Box
              width="400px"
              alignSelf={"center"}
              alignItems={"left"}
              justifyContent={"left"}
            >
              <Text>{t("status")}</Text>
              <SelectFilter
                value={isCollected}
                options={collection_options}
                setField={setIsCollected}
              />
            </Box>
            <Box marginLeft="auto">
              {usePageComponentAccess("Society Demand Add Member") && (
                <Button
                  style={{
                    height: "45px",
                    borderRadius: "5px",
                  }}
                  isLoading={false}
                  width="100px"
                  colorScheme="primary"
                  variant="solid"
                  onClick={() => {
                    handleAddRecoveryModalOpen();
                  }}
                >
                  {"Add Member"}
                </Button>
              )}
            </Box>
            <Box>
              <SearchBox
                label={t("table:Search")}
                width={{
                  base: "300px",
                  xs: "200px",
                  sm: "200px",
                  md: "300px",
                }}
                onSearch={handleSearch}
              />
            </Box>
          </HStack>
          <div
            style={{ width: "100%", overflowX: "auto", overflowY: "hidden" }}
          >
            <Table
              rowClassName="custom-table"
              dataSource={default_data}
              loading={demand?.loading}
              pagination={false}
              bordered
              scroll={{ x: true }}
              onRow={(record) => {
                return (
                  <>
                    {record?.id === "new" ? (
                      <Box></Box>
                    ) : (
                      <Box backgroundColor={"amber.200"}></Box>
                    )}
                  </>
                );
              }}
              summary={(pageData) => {
                // Initialize variables to store column-wise totals

                let duetoTotal = 0;
                let recurringDepositTotal = 0;
                let depositProductTotals = {};
                let loanProductTotals = {};
                let generated_demand_amount = 0;

                // Calculate totals for deposit_products
                deposit_products?.forEach((depositName) => {
                  depositProductTotals[depositName.id] = {
                    amountTotal: 0,
                    arrearAmountTotal: 0,
                  };
                });

                loan_products?.forEach((product) => {
                  loanProductTotals[product.id] = {
                    arrearPrincipalTotal: 0,
                    principalTotal: 0,
                    arrearInterestTotal: 0,
                    interestTotal: 0,
                    decreeInterestTotal: 0,
                    arrearPenalInterestTotal: 0,
                    penalInterestTotal: 0,
                  };
                });

                // Iterate over each record in pageData
                pageData.forEach((record) => {
                  generated_demand_amount += record?.generated_demand_amount;
                  record.surety_loans?.forEach((loan) => {
                    const product = loan.surety_loan_product;
                    if (product && loanProductTotals[product.id]) {
                      loanProductTotals[product.id].arrearPrincipalTotal +=
                        loan?.surety_loan_demands?.[0]
                          ?.arrear_monthly_principal || 0;
                      loanProductTotals[product.id].principalTotal +=
                        total_principal(loan);
                      loanProductTotals[product.id].arrearInterestTotal +=
                        loan?.surety_loan_demands?.[0]?.arrear_interest || 0;
                      loanProductTotals[product.id].interestTotal +=
                        loan?.surety_loan_demands?.[0]?.fixed_demand_amount &&
                        loan?.surety_loan_demands?.[0]?.fixed_demand_amount !==
                          0
                          ? Number(
                              loan?.surety_loan_demands?.[0]
                                ?.fixed_demand_amount
                            ) -
                            Number(
                              loan?.surety_loan_demands?.[0]
                                ?.monthly_principal >
                                loan?.surety_loan_demands?.[0]
                                  ?.current_principal_balance
                                ? loan?.surety_loan_demands?.[0]
                                    ?.current_principal_balance
                                : loan?.surety_loan_demands?.[0]
                                    ?.monthly_principal || 0
                            )
                          : loan?.surety_loan_demands?.[0]?.interest || 0;
                      loanProductTotals[product.id].decreeInterestTotal +=
                        loan?.surety_loan_demands?.[0]?.decree_interest || 0;
                      loanProductTotals[product.id].arrearPenalInterestTotal +=
                        loan?.surety_loan_demands?.[0]?.arrear_penal_interest ||
                        0;
                      loanProductTotals[product.id].penalInterestTotal +=
                        loan?.surety_loan_demands?.[0]?.penal_interest || 0;
                    }
                  });

                  deposit_products?.forEach((depositName) => {
                    const deposit = record?.deposits?.find(
                      (d) => d.deposit_product?.id === depositName.id
                    );
                    if (deposit) {
                      depositProductTotals[depositName.id].amountTotal +=
                        (deposit.amount || deposit.current_amount) ?? 0;
                      depositProductTotals[depositName.id].arrearAmountTotal +=
                        Number(deposit?.arrear_amount) +
                        Number(deposit?.current_month_balance);
                    }
                  });

                  // Calculate total for recurring_deposit_products
                  record?.recurring_deposit?.forEach((deposit, i) => {
                    recurringDepositTotal +=
                      (deposit.amount ?? 0) + (deposit.arrear_amount ?? 0);
                  });

                  // Calculate total for Dueto
                  duetoTotal += record?.member_duetos?.reduce(
                    (dueto_sum, dueto) => {
                      return dueto_sum + (dueto.balance_amount || 0);
                    },
                    0
                  );
                });

                const depositProductSummary = deposit_products?.map(
                  (depositName) => (
                    <React.Fragment>
                      <Table.Summary.Cell
                        key={`deposit-${depositName.id}`}
                        align="right"
                      >
                        {depositProductTotals[depositName.id].amountTotal !== 0
                          ? amountFormat(
                              depositProductTotals[depositName.id].amountTotal
                            )
                          : "-"}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        key={`arrear-${depositName.id}`}
                        align="right"
                      >
                        {depositProductTotals[depositName.id]
                          .arrearAmountTotal !== 0
                          ? amountFormat(
                              depositProductTotals[depositName.id]
                                .arrearAmountTotal
                            )
                          : "-"}
                      </Table.Summary.Cell>
                    </React.Fragment>
                  )
                );

                // Render totals for recurring_deposit_products
                const recurringDepositSummary = (
                  <Table.Summary.Cell align="right">
                    {recurringDepositTotal !== 0
                      ? amountFormat(recurringDepositTotal)
                      : "-"}
                  </Table.Summary.Cell>
                );

                // Render total for Dueto
                const duetoSummary = (
                  <Table.Summary.Cell align="right">
                    {duetoTotal !== 0 ? amountFormat(duetoTotal) : "-"}
                  </Table.Summary.Cell>
                );

                const loanProductSummary = loan_products?.map((product) => (
                  <React.Fragment key={product.id}>
                    <Table.Summary.Cell>
                      {loanProductTotals[product.id].arrearPrincipalTotal !== 0
                        ? amountFormat(
                            loanProductTotals[product.id].arrearPrincipalTotal
                          )
                        : "-"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {loanProductTotals[product.id].principalTotal !== 0
                        ? amountFormat(
                            loanProductTotals[product.id].principalTotal
                          )
                        : "-"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {loanProductTotals[product.id].arrearInterestTotal !== 0
                        ? amountFormat(
                            loanProductTotals[product.id].arrearInterestTotal
                          )
                        : "-"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {loanProductTotals[product.id].interestTotal !== 0
                        ? amountFormat(
                            loanProductTotals[product.id].interestTotal
                          )
                        : "-"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {loanProductTotals[product.id].decreeInterestTotal !== 0
                        ? amountFormat(
                            loanProductTotals[product.id].decreeInterestTotal
                          )
                        : "-"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {loanProductTotals[product.id]
                        .arrearPenalInterestTotal !== 0
                        ? amountFormat(
                            loanProductTotals[product.id]
                              .arrearPenalInterestTotal
                          )
                        : "-"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {loanProductTotals[product.id].penalInterestTotal !== 0
                        ? amountFormat(
                            loanProductTotals[product.id].penalInterestTotal
                          )
                        : "-"}
                    </Table.Summary.Cell>
                  </React.Fragment>
                ));
                // Render totals for each column group
                return (
                  <React.Fragment>
                    <Table.Summary>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={2} align="center">
                          Total
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          {generated_demand_amount !== 0
                            ? amountFormat(generated_demand_amount)
                            : "-"}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          {total_recovery_amount !== 0
                            ? amountFormat(total_recovery_amount)
                            : "-"}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        {loanProductSummary}
                        {depositProductSummary}
                        {recurringDepositSummary}
                        {duetoSummary}
                      </Table.Summary.Row>
                    </Table.Summary>
                  </React.Fragment>
                );
              }}
            >
              {columns}
            </Table>
            <HStack justifyContent={"flex-end"} mt="5">
              <Pagination
                total={demand?.total_count}
                showSizeChanger={true}
                page_number={page_number}
                hideOnSinglePage={false}
                onChange={fetchData}
              />
            </HStack>
          </div>
        </>
        <HStack mb={5} justifyContent={"flex-end"}>
          <Text bold fontSize={"16px"}>
            {t("total_collection_amount")}:{" "}
            {amountFormat(total_recovery_amount)}{" "}
          </Text>
        </HStack>
        {isCollected !== "collected" &&
          total_recovery_amount > 0 &&
          (usePageComponentAccess("Society Demand Collection Action") ? (
            <HStack mb={5} justifyContent={"flex-end"}>
              <Button
                onClick={() => {
                  handleAddCollection();
                }}
                type="primary"
                disabled={
                  total_recovery_amount <= selected_amount?.amount
                    ? false
                    : true
                }
                // disabled={
                //   !(
                //     payofficer_dueby_sub_dept_items?.items?.[0]
                //       ?.balance_amount > 0 ||
                //     payofficer_dueby_dept_items?.items?.[0]?.balance_amount > 0
                //   )
                // }
              >
                {t("record_collection")}
              </Button>
            </HStack>
          ) : null)}
      </VStack>
      <Modal
        isOpen={collection_modal_open}
        onClose={handleAddCollectionClose}
        header={t("Demand Collection")}
        component={
          <RecordCollection
            demand_id={id}
            demand={demand}
            close={handleAddCollectionClose}
            data={default_data}
            group_id={sub_department_id}
            selected_amount={selected_amount}
            old_balance={old_balance}
          />
        }
        source={{}}
        group={"collection_details"}
        width={"800px"}
      />
      <Modal
        isOpen={is_delete_modal_open}
        onClose={handleDeleteModalClose}
        header={t("delete_member")}
        component={
          <>
            <Text>
              {t("delete_member_content", {
                name: member_name,
              })}
            </Text>

            <HStack
              mt={10}
              width={"100%"}
              justifyContent={"flex-end"}
              space={5}
            >
              <Button
                type="primary"
                loading={false}
                onClick={() => {
                  handleDelete();
                }}
                // style={{
                //   backgroundColor: "#FF0000",
                // }}
                danger
              >
                {t("remove")}
              </Button>
              <Button
                type="default"
                onClick={() => {
                  handleDeleteModalClose();
                }}
              >
                {t("cancel")}
              </Button>
            </HStack>
          </>
        }
        source={{}}
        group={"transaction_modal"}
        width={"500px"}
      />
      <Modal
        isOpen={is_add_recovery_model_open}
        onClose={handleAddRecoveryModalClose}
        header={"Add Recovery For Member"}
        component={
          <AddRecoveryDetails
            demand={demand}
            default_data={default_data}
            setDefaultData={setDefaultData}
            date={demand?.demand_date}
            close={handleAddRecoveryModalClose}
            sub_department_id={demand?.sub_department?.id}
            officer={`${demand?.sub_department?.department?.name} - ${demand?.sub_department?.name}`}
          />
        }
        source={{}}
        width={"400px"}
      />
      <CollectionAmountSelectionModal
        selected_amount={selected_amount}
        set_selected_amount={set_selected_amount}
        ref={collection_amount_modal_ref}
      />
    </Box>
  );
};

export default memo(DemandCollectionList);
