import { useEffect, useRef, useState } from "react";
import { Button, Table, Form } from "antd";
import { amountFormat } from "../../../helpers/utils";
import { useTranslation } from "react-i18next";
import { HStack } from "native-base";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { showToast } from "@helpers/toast";
import { add_bulk_suspense_mutation } from "services/redux/slices/dynamic_entity/graphql/graphql_suspense_bulk_receipt";

const SuspenseBulkUploadConfirmation = (props) => {
  const { excel_response, close, form, first_modal, fetch_data } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [has_error, set_has_error] = useState(false);
  const error_ref = useRef({ if_found_error: false });
  const bank_account_id = Form.useWatch("bank_account_id", form);
  const url = Form.useWatch("url", form);

  const {
    loading: createLoading,
    status: bulk_status,
    error: bulk_error,
  } = useDynamicSelector("createBulkSuspenseAdd");

  useEffect(() => {
    let found_error =
      excel_response.length === 0 || excel_response.some((row) => !row.Amount);
    set_has_error(found_error);
  }, [excel_response]);

  const columns = [
    {
      title: t("table:amount"),
      dataIndex: "Amount",
      key: "amount",
      render: (amount) => {
        if (amount) {
          return amountFormat(amount);
        } else {
          error_ref.current.if_found_error = true;
          return <h5 className="text-red">Data Missing</h5>;
        }
      },
      align: "right",
      flex: 1,
    },
    {
      title: t("table:transaction_reference"),
      dataIndex: "Transaction Reference",
      key: "Transaction Reference",
      align: "center",
      flex: 1,
      render: (txn_reference) => txn_reference ?? "-",
    },
    {
      title: t("table:payofficer_code"),
      dataIndex: "Pay Officer Code",
      key: "Pay Officer Code",
      align: "center",
      flex: 1,
      render: (code) => code ?? "-",
    },
  ];

  const handle_ok = () => {
    let variables = {
      url: url?.[0]?.url,
      bank_account_id,
    };
    let keys = [{ key: "createBulkSuspenseAdd", loading: true }];
    dispatch(dynamicRequest(keys, add_bulk_suspense_mutation, variables, "M"));
  };

  useEffect(() => {
    if (bulk_error) {
      showToast({ type: "error", message: t("error_message") });
      dispatch(dynamicClear("createBulkSuspenseAdd"));
    } else if (bulk_status === "Success") {
      showToast({
        type: "success",
        message: `${t(
          "Suspense Bulk Receipt upload successfully waits a few minutes to be refuted."
        )}`,
      });
      close();
      fetch_data();
      first_modal();
      dispatch(dynamicClear("createBulkSuspenseAdd"));
    }
  }, [bulk_status, bulk_error]);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={excel_response}
        pagination={false}
        summary={() => {
          let total = excel_response.reduce(
            (sum, acc) => (sum += Number(acc.Amount || 0)),
            0
          );
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell align="right">
                <div className="suspense-bulk-receipt-total-field">
                  <div>
                    <b>Total</b>
                  </div>
                  <div>
                    <b>{amountFormat(total)}</b>
                  </div>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell colSpan={1}></Table.Summary.Cell>
              <Table.Summary.Cell colSpan={1}></Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
      <HStack justifyContent={"flex-end"} mt={"30px"} space={"10px"}>
        {!has_error && (
          <Button type="primary" loading={createLoading} onClick={handle_ok}>
            OK
          </Button>
        )}
        <Button
          danger
          onClick={() => {
            close();
          }}
        >
          Close
        </Button>
      </HStack>
    </div>
  );
};

export default SuspenseBulkUploadConfirmation;
