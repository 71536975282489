import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { Form as AntdForm, Col } from "antd";
import { useHistory } from "react-router-dom";
import {
  create_society_dividend_setting,
  createSocietyDividendSettingSelector,
  update_society_dividend_setting,
  updateSocietyDividendSettingSelector,
  get_gl_accountss,
  getGlAccountsListSelector,
} from "@services/redux";
import { Button } from "antd";
import moment from "moment";
import {
  dynamicClear,
  dynamicRequest,
  get_dividend_percentage_query,
  get_profit_query,
  query_accounting_date,
  useDynamicSelector,
} from "../../../../services/redux";
import { create_society_dividend_setting_mutation } from "../../../../services/redux/slices/society_dividend_setting/graphql";
import { get_all_member_list_query } from "../../../../services/redux/slices/member/graphql";
import { usePageComponentAccess } from "../../../../helpers/auth";
import { amountFormat } from "helpers/utils";
import { showToast } from "helpers/toast";

const SocietyDividendSettingDetails = (props) => {
  const dispatch = useDispatch();
  const [form] = AntdForm.useForm();
  const amount_watch = AntdForm.useWatch("dividend_setting_percentage", form);
  const [percentage_total, set_percentage_total] = useState(null);
  const [percentage_error, set_percentage_error] = useState(null);
  const percentage_watch = AntdForm.useWatch("total_dividend_percentage", form);
  
  const { loading: createLoading } = useSelector(
    createSocietyDividendSettingSelector
  );
  const { loading: updateLoading } = useSelector(
    updateSocietyDividendSettingSelector
  );

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const profit = useDynamicSelector("getProfit");
  const dividendPercentage = useDynamicSelector("getDividendPercentage");
  const { items, pagination } = useDynamicSelector("get_member_list");
  const { loading: societyDividendSettingCreateLoading } = useDynamicSelector(
    "createDividendSetting"
  );

  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const { items: gl_accountss } = useSelector(getGlAccountsListSelector);
  const [actionItem, setActionItem] = useState({
    dividend_setting_percentage: [{}],
  });
  const [total_percentage, setTotalPercentage] = useState();
  const [show_error, setShowError] = useState(false);
  const [value, setValue] = useState(null);
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    let _total = amount_watch?.reduce((acc, crr) => {
      return acc + Number(crr?.percentage_value);
    }, 0);

    if (_total > 100) {
      set_percentage_error(true);
      showToast({
        type: "error",
        message: "Total percentage should not higher than 100",
      });
    } else {
      set_percentage_error(false);
    }
    return ()=>{
      dispatch(dynamicClear("getDividendPercentage"));
    }
  }, [amount_watch]);

  useEffect(() => {
    form.setFieldsValue({
      dividend_percentage: dividendPercentage?.percentage,
    });
    return () => {
      dispatch(dynamicClear("dividendPercentage"));
    };
  }, [dividendPercentage]);

  useEffect(() => {
    if (amount_watch?.[0]?.amount) {
      getDividendPercentageQuery();
    }
  }, [amount_watch]);

  function getYearsFrom2023() {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 2022; year <= currentYear; year++) {
      years.push({
        name: `${year} - ${year + 1}`,
        id: year,
      });
    }
    return years;
  }

  const currentYear = getYearsFrom2023();

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const getProfitQuery = (year) => {
    let key = [{ key: "getProfit", loading: true }];
    let query = get_profit_query;
    let variables = { financial_year: year };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getDividendPercentageQuery = () => {
    let key = [{ key: "getDividendPercentage", loading: true }];
    let query = get_dividend_percentage_query;
    let variables = {
      financial_year: value?.financial_year,
      // amount: profit.amount,
      amount: Number(amount_watch?.[0]?.amount),
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllMembersQuery = () => {
    let key = [{ key: "get_member_list", loading: true }];
    let query = get_all_member_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleSubmit = (values) => {
    let amount_total = values?.dividend_setting_percentage.reduce((acc,curr) =>{
      return acc = acc + Number(curr.amount)
    },0);

    console.log("amount_total ", amount_total, values.profit_amount);

    if(!(amount_total == values.profit_amount)){
      showToast({type:"error",message:"Profit amount and entered amount does not match"})
      return; 
    }

    delete values?.profit_amount_first;
    values.profit_amount = parseFloat(values.profit_amount)
    values = {
      ...values,
      dividend_setting_percentage: values?.dividend_setting_percentage.map(
        (item) => ({
          ...item,
          percentage_value: parseFloat(item.percentage_value),
          amount: parseFloat(item.amount),
        })
      ),
    };
    delete values.total_dividend_percentage;
    values.dividend_percentage = Number(values.dividend_percentage);
    let total_percentage = values?.dividend_setting_percentage?.reduce(
      (sum, val) => sum + Number(val.percentage_value),
      0
    );
    setTotalPercentage(parseFloat(total_percentage));
    // if (100 - total_percentage > 0) {
      setShowError(false);
      if (values.id) {
        let update_id = values?.id;
        delete values?.id;
        delete values?.error;
        values.dividend_setting_percentage[0].is_dividend = true;
        dispatch(
          update_society_dividend_setting({ id: update_id, data: values })
        );
      } else {
        let key = [{ key: "createDividendSetting", loading: true }];
        let query = create_society_dividend_setting_mutation;
        values.dividend_setting_percentage[0].is_dividend = true;
        let variables = {
          data: values,
        };
        dispatch(dynamicRequest(key, query, variables));
      }
    // } else {
    //   setShowError(true);
    // }
  };

  useEffect(() => {
    getAccountingDate();
    dispatch(get_gl_accountss());
    getAllMembersQuery();
  }, []);

  useEffect(() => {
    if (profit?.error){
      showToast({type:"error",message:profit?.error?.message})
    }
  }, [profit]);

  useEffect(()=>{
    if(value?.financial_year){
      form.setFieldsValue({
        profit_amount:0,
        profit_amount_first:0,
      });
    }
  },[value?.financial_year]);

  useEffect(() => {
    
    if (value?.financial_year) {
      // getDividendPercentageQuery();
      form.setFieldsValue({
        profit_amount_first: Math.round(profit?.amount).toFixed(2),
        profit_amount: Math.round(profit?.amount).toFixed(2),
      });

      setActionItem({
        ...actionItem,
        profit_amount_first: Math.round(profit?.amount).toFixed(2),
        dividend_setting_percentage: [{}],
        profit_amount: Math.round(profit?.amount).toFixed(2),
      });
    }
  }, [profit]);

  // useEffect(() => {
  //   if (dividendPercentage?.percentage) {
  //     form.setFieldsValue({
  //       total_dividend_percentage: dividendPercentage?.percentage,
  //       dividend_setting_percentage: [{}],
  //     });
  //     setActionItem({
  //       ...actionItem,
  //       total_dividend_percentage: dividendPercentage?.percentage,
  //       dividend_setting_percentage: [{}],
  //     });
  //   }
  // }, [dividendPercentage]);

  const handleChange = (val, value) => {
    // if (value.dividend_setting_percentage[0].percentage_value) {
    //   value.amount = (Number(value.profit_amount)* Number(value.dividend_setting_percentage[0].percentage_value)) / 100;
    //   setActionItem(value);
    //   form.setFieldsValue({ amount: value.amount });
    // }
    // if (value.dividend_setting_percentage[0].amount) {
    //   value.percentage_value = (value.dividend_setting_percentage[0].amount * 100) / Number(value.profit_amount);
    //   setActionItem(value);
    //   form.setFieldsValue({ percentage_value: value.percentage_value });
    // }

    if (value.profit_amount){
      form.setFieldsValue({
        profit_amount: value.profit_amount,
      });
    }

    if (val.financial_year){
      getProfitQuery(val.financial_year);
    }

    // if (value.dividend_setting_percentage?.length) {
    //   value.dividend_setting_percentage.map((x, i) => {
    //     if (x?.percentage_value) {
    //       let amount = (value.profit_amount * x.percentage_value) / 100;
    //       Object.assign(value.dividend_setting_percentage[i], {
    //         amount: Number(amount.toFixed(2)),
    //       });
    //       form.setFieldsValue({
    //         dividend_setting_percentage: value.dividend_setting_percentage,
    //       });
    //     }
    //     if (x?.amount) {
    //       let percentage_value = (x.amount * 100) / value.profit_amount;
    //       Object.assign(value.dividend_setting_percentage[i], {
    //         percentage_value: percentage_value.toFixed(2),
    //       });
    //       form.setFieldsValue({
    //         dividend_setting_percentage: value.dividend_setting_percentage,
    //       });
    //     }
    //   });
    // }
    if (value.dividend_setting_percentage?.length) {
      form.setFieldsValue({
        dividend_percentage: (
          Number(dividendPercentage?.percentage || 0) / 100
        ).toFixed(2),
      });
    }
    if (value.financial_year) setValue(value);
  };
  const handle_amount_change = (number, index) => {
    const get_dividend_setting_percentage = form.getFieldValue("dividend_setting_percentage");
    const get_profit_amount = parseFloat(form.getFieldValue("profit_amount")); 
    
    const percentage = (number * 100) / get_profit_amount;
    const amount = number; 
    
    const json = {
      ...get_dividend_setting_percentage[index],
      percentage_value: Math.round(percentage).toFixed(2),
      amount: Number(amount).toFixed(2) 
    };
  
    const updatedArray = get_dividend_setting_percentage.map((item, i) => 
      i === index ? json : item
    );
  
    form.setFieldsValue({ dividend_setting_percentage: updatedArray });
  };
  
  const handle_percentage_change = (percentage, index) => {
    const get_dividend_setting_percentage = form.getFieldValue("dividend_setting_percentage");
    const get_profit_amount = parseFloat(form.getFieldValue("profit_amount")); 
  
    const amount = (get_profit_amount * percentage) / 100;
  
    const json = {
      ...get_dividend_setting_percentage[index],
      percentage_value: Math.round(percentage).toFixed(2),
      amount: Number(amount).toFixed(2)
    };
  
    const updatedArray = get_dividend_setting_percentage.map((item, i) => 
      i === index ? json : item
    );
  
    form.setFieldsValue({ dividend_setting_percentage: updatedArray });
  };
  
  return (
    <AntdForm
      initialValues={actionItem}
      onFinish={handleSubmit}
      form={form}
      layout="vertical"
      onValuesChange={handleChange}
      disabled={
        usePageComponentAccess("Society Profit Appropriation View")
          ? true
          : false
      }
    >
      <HStack space={4}>
        <Col style={{ width: "298px" }}>
          <Form.Select
            label={t("form:financial_year")}
            field={"financial_year"}
            options={currentYear}
            rules={[
              {
                required: true,
                message: t("error:financial_year_is_required"),
              },
            ]}
          />
        </Col>

        <Col style={{ width: "298px" }}>
          <Form.Number
            label={t("form:profit_amount")}
            field={"profit_amount_first"}
            disabled
            notShow={true}
            form={form}
          />
        </Col>
        <Col style={{ width: "298px" }}>
          <Form.Number
            label={t("form:profit_amount")}
            field={"profit_amount"}
            notShow={false}
            form={form}
          />
        </Col>
      </HStack>

      <Box flex={1} style={{ width: "298px" }}>
        <Form.Number
          hidden
          label={t("form:dividend_percentage")}
          field={"total_dividend_percentage"}
          notShow={true}
        />
      </Box>

      <VStack>
        <Box flex={1} style={{ width: "298px" }}>
          <Form.Number
            label={t("form:dividend_percentage")}
            field={"dividend_percentage"}
            notShow={true}
          />
        {dividendPercentage?.dividend_amount &&
          amountFormat(dividendPercentage?.dividend_amount)}
          </Box>
      </VStack>

      <AntdForm.List name="dividend_setting_percentage" shouldUpdate>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <div key={field.key}>
                  {/* <Divider>Document {index + 1}</Divider> */}
                  <HStack space={"5"}>
                    <Box flex={1}>
                      <Form.Select
                        label={
                          index === 0
                            ? t("form:gl_account_dividend")
                            : t("form:gl_account")
                        }
                        field={[index, "gl_account_id"]}
                        options={gl_accountss}
                        rules={[
                          {
                            required: true,
                            message: t("error:gl_account_is_required"),
                          },
                        ]}
                      />
                    </Box>
                    <Box flex={1}>
                      <Form.Number
                        field={[index, "percentage_value"]}
                        label={t("form:percentage_value")}
                        onChange={(e)=>handle_percentage_change(e,index)}
                        notShow={true}
                        rules={[
                          {
                            required: true,
                            message: t("error:percentage_value_is_required"),
                          },
                        ]}
                      />
                    </Box>
                    <Box flex={1}>
                      <Form.Number
                        field={[index, "amount"]}
                        label={t("form:amount")}
                        onChange={(e)=>handle_amount_change(e,index)}
                        rules={[
                          {
                            required: true,
                            message: t("error:amount_is_required"),
                          },
                        ]}
                      />
                    </Box>
                    <Box>
                      {usePageComponentAccess(
                        "Society Profit Appropriation Add"
                      ) && index > 1 ? (
                        <Button
                          type="default"
                          danger
                          width="200px"
                          className="dynamic-delete-button-remove"
                          onClick={() => remove(field.name)}
                          // icon={<MinusCircleOutlined style={{ mt: "3px" }} />}
                        >
                          Remove
                        </Button>
                      ) : null}
                    </Box>
                  </HStack>
                </div>
              ))}
              <AntdForm.Item>
                {!usePageComponentAccess(
                  "Society Profit Appropriation View"
                ) && (
                  <Button
                    width="200px"
                    type="dashed"
                    onClick={() => add()}
                    style={{ marginTop: "5px" }}
                  >
                    Add
                  </Button>
                )}
              </AntdForm.Item>
            </div>
          );
        }}
      </AntdForm.List>
      {show_error ? (
        <Text color="red.600" textAlign={"right"}>
          {"Dividend amount is greater than profit amount"}
        </Text>
      ) : (
        ""
      )}
      {/* <Row gutter={16}>
          <Col span={8}>
            <Text>{t("form:financial_year")}</Text>
          </Col>
          <Col span={8}>
            <Form.Date field={"financial_year"} label={""} />
          </Col>
          <Col span={8}>
            <Form.Number field={"financial_year"} label={t("")} />
          </Col>
          <Col span={8}>
            <Text>{t("form:reserve_fund_percentage")}</Text>
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"reserve_fund_percentage"}
              label={""}
            />
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"reserve_fund_percentage"}
              label={t("")}
            />
          </Col>
          <Col span={8}>
            <Text>{t("form:coop_research_dev_percentage")}</Text>
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"coop_research_dev_percentage"}
              label={""}
            />
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"coop_research_dev_percentage"}
              label={t("")}
            />
          </Col>
          <Col span={8}>
            <Text>{t("form:coop_education_percentage")}</Text>
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"coop_education_percentage"}
              label={""}
            />
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"coop_education_percentage"}
              label={t("")}
            />
          </Col>
          <Col span={8}>
            <Text>{t("form:bdr_percentage")}</Text>
          </Col>
          <Col span={8}>
            <Form.Number notShow={true} field={"bdr_percentage"} label={""} />
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"bdr_percentage"}
              label={t("")}
            />
          </Col>
          <Col span={8}>
            <Text>{t("form:cgf_percentage")}</Text>
          </Col>
          <Col span={8}>
            <Form.Number notShow={true} field={"cgf_percentage"} label={""} />
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"cgf_percentage"}
              label={t("")}
            />
          </Col>
          <Col span={8}>
            <Text>{t("form:building_fund_percentage")}</Text>
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"building_fund_percentage"}
              label={""}
            />
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"building_fund_percentage"}
              label={t("")}
            />
          </Col>
          <Col span={8}>
            <Text>{t("form:honorarium_percentage")}</Text>
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"honorarium_percentage"}
              label={""}
            />
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"honorarium_percentage"}
              label={t("")}
            />
          </Col>
        </Row> */}
      <HStack space={8} justifyContent="flex-end"  >
        {!usePageComponentAccess("Society Profit Appropriation View") &&
          !percentage_error && (
           <div className="profit-appropriation-bottom-actions">
             <Form.Button
              isLoading={societyDividendSettingCreateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
              className="profit-appropriation-bottom-actions-button"
            >
              {t("submit")}
            </Form.Button>
           </div>
          )}
        <Button
          onClick={props.close}
          type="warning"
          variant="outline"
          danger
          disabled={false}
          className="profit-appropriation-bottom-actions-button"
        >
          {t("close")}
        </Button>
      </HStack>
    </AntdForm>
  );
};
export default SocietyDividendSettingDetails;
